<template>
  <div class="norte" v-if="!loading">
    <div v-if="!walletAddressRef">
      <div class="connect-wallet-alert-div">
        <el-alert
          class="connect-wallet-alert"
          :closable="false"
          effect="dark"
          type="warning"
          show-icon
        >
          <h2>Please connect your wallet to use Norte</h2>
        </el-alert>
      </div>
    </div>
    <div v-else>
      <div v-if="!tokenNotFound">
        <div v-if="isTokenOwner" class="main-container">
          <el-row>
            <el-col
              :xs="24"
              :sm="10"
              :md="13"
              :lg="14"
              :xl="15"
              class="left-container"
            >
              <div class="grid-content bg-purple"></div>
              <Player />
            </el-col>
            <el-col
              :xs="24"
              :sm="14"
              :md="11"
              :lg="10"
              :xl="9"
              class="right-container"
            >
              <div class="grid-content bg-purple-light"></div>
              <ToolPanel />
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-else>
        <el-result
          icon="warning"
          title="404"
          sub-title="We're sorry, we couldn't find that anywhere in the Studio."
        >
          <template #extra>
            <el-link href="/" :underline="false">
              <el-button type="primary" size="default">Back</el-button>
            </el-link>
          </template>
        </el-result>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, ref, watch, onBeforeMount } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'

  import ToolPanel from '@/components/projects/norte/norte-mint/tool-panel'
  import Player from '@/components/projects/norte/norte-mint/player'
  import { selectProjectContract } from '@/services/ProjectService'
  import { getToken, isOwnerOf } from '@/services/TokenService'

  export default {
    name: 'Norte',

    components: {
      ToolPanel,
      Player,
    },

    setup() {
      // Hooks
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user },
      } = store

      const walletAddress = computed(() => user.walletAddress)
      const contract = computed(() => contractState.contract)
      const web3 = computed(() => contractState.web3)

      const walletAddressRef = ref(walletAddress)
      const contractRef = ref(contract)
      const tokenNotFound = ref(false)
      const route = useRoute()
      const router = useRouter()

      const projectSlug = route.params.project_slug
      const tokenID = route.params.token_id
      const isTokenOwner = ref(true)
      const loading = ref(true)

      async function setToken() {
        const tokenResponse = await getToken(projectSlug, tokenID)
        const token = tokenResponse.response
        if (token) {
          if (walletAddressRef.value && contractRef.value) {
            isTokenOwner.value = await isOwnerOf(
              tokenID,
              walletAddressRef.value,
              contractRef.value
            )
            if (isTokenOwner.value) {
              dispatch('setMode', token.mode)
              dispatch('setStyle', token.style)
              dispatch('setVar1', token.var1)
              dispatch('setVar2', token.var2)
              dispatch('setBackground', token.blackBackground)
              dispatch('setShowAddress', token.displayAddress)
            } else {
              router.push({
                path: `/tokens/${projectSlug}/${tokenID}`,
                query: {
                  notOwner: true,
                },
              })
            }
          }
        } else {
          tokenNotFound.value = true
        }
        loading.value = false
      }

      onBeforeMount(async () => {
        dispatch('setEditMode', true)
        await setToken()

        if (web3.value) {
          await selectProjectContract(projectSlug, web3.value, dispatch)
        }
      })

      watch([walletAddressRef, contractRef], async () => {
        await setToken()
      })

      watch([web3], async () => {
        await selectProjectContract(projectSlug, web3.value, dispatch)
      })

      return {
        walletAddressRef,
        isTokenOwner,
        tokenNotFound,
        loading,
      }
    },
  }
</script>

<style scoped>
  .norte {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-container {
    max-width: 1500px;
  }

  .left-container,
  .right-container {
    padding: 2px;
    width: 100%;
    height: 100%;
  }
</style>
